// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCyySvp6kcdoo4BL0mN8Bd8wFJF5K-nljg",
  authDomain: "tel-react.firebaseapp.com",
  projectId: "tel-react",
  storageBucket: "tel-react.appspot.com",
  messagingSenderId: "607754914037",
  appId: "1:607754914037:web:d89b51055aa8e281f41dbc"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const getContacts = async (userId) => {
  const contacts = [];
  const userDocPath = `users/${userId}/contacts`;
  const q = query(collection(db, userDocPath));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    contacts.push({ id: doc.id, ...doc.data() });
  });
  return contacts;
};

const updateUserAccess = async (userId, allowMobile) => {
  const userDoc = doc(db, `users/${userId}`);
  await updateDoc(userDoc, { allowMobile });
};

// New function to get the current user's ID
const getCurrentUserId = () => {
  const user = auth.currentUser;
  return user ? user.uid : null;
};

// New function to listen for auth state changes
const onAuthStateChange = (callback) => {
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      callback({ loggedIn: true, userId: user.uid });
    } else {
      callback({ loggedIn: false, userId: null });
    }
  });
};

export { db, auth, getContacts, updateUserAccess, getCurrentUserId, onAuthStateChange };