// src/App.js
import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Button, Center, Spinner, HStack } from '@chakra-ui/react';
import Login from './components/Login';
import Contacts from './components/Contacts';
import CallSection from './components/CallSection';
import { auth } from './firebase';

const serverUrl = 'https://morhla2.pythonanywhere.com';

const App = () => {
 const [isAuthenticated, setIsAuthenticated] = useState(false);
 const [selectedContacts, setSelectedContacts] = useState([]);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
   const unsubscribe = auth.onAuthStateChanged(user => {
     setIsAuthenticated(!!user);
     setLoading(false);
   });

   return () => unsubscribe();
 }, []);

 const handleLogin = () => {
   setIsAuthenticated(true);
 };

 const handleLogout = async () => {
   await auth.signOut();
   setIsAuthenticated(false);
 };

 if (loading) {
   return (
     <Center h="100vh">
       <Spinner size="xl" />
     </Center>
   );
 }

 return (
   <Box p={4}>
     {isAuthenticated ? (
       <>
         <HStack justifyContent="space-between" mb={4} flexWrap="wrap">
           <Button onClick={handleLogout}>התנתק</Button>
         </HStack>
         <Heading mb={4} textAlign="center">מערכת שיחות</Heading>
         <VStack spacing={8} align="stretch">
           <Contacts onContactSelect={setSelectedContacts} />
           <CallSection 
             selectedContacts={selectedContacts} 
             serverUrl={serverUrl}
           />
         </VStack>
       </>
     ) : (
       <Login onLogin={handleLogin} />
     )}
   </Box>
 );
};

export default App;