// src/components/CallSection.js
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Button, Input, Text, VStack, HStack, useToast,
  Table, Thead, Tbody, Tr, Th, Td, Badge
} from '@chakra-ui/react';
import { PhoneIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { db, auth } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const FROM_NUMBERS = [
  '+972524038949',
  '+972524038275',
  '+972523978077',
  '+972524038259',
  '+972524038218',
  '+972524023900',
  '+972523988003',
  '+972524055636'
];

let currentNumberIndex = 0;

const getNextFromNumber = () => {
  const number = FROM_NUMBERS[currentNumberIndex];
  currentNumberIndex = (currentNumberIndex + 1) % FROM_NUMBERS.length;
  return number;
};

const formatPhoneNumber = (phone) => {
  let cleanPhone = phone.replace(/[^\d+]/g, '');
  if (cleanPhone.startsWith('+972')) {
    return cleanPhone;
  }
  if (cleanPhone.startsWith('972')) {
    return '+' + cleanPhone;
  }
  if (cleanPhone.startsWith('0')) {
    return '+972' + cleanPhone.slice(1);
  }
  return '+972' + cleanPhone;
};

const displayPhoneNumber = (phone) => {
  if (phone.startsWith('+972')) {
    return '0' + phone.slice(4);
  }
  return phone;
};

const CallSection = ({ selectedContacts, serverUrl }) => {
  const [agentPhone, setAgentPhone] = useState('');
  const [maxAttempts, setMaxAttempts] = useState('3'); // ברירת מחדל 3, נשמר כטקסט
  const [callStatus, setCallStatus] = useState('idle');
  const [callsMade, setCallsMade] = useState({});
  const [isCallActive, setIsCallActive] = useState(false);
  const [contacts, setContacts] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchContacts = async () => {
      const user = auth.currentUser;
      if (user) {
        const contactsRef = collection(db, `users/${user.uid}/contacts`);
        const snapshot = await getDocs(contactsRef);
        const contactsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setContacts(contactsList);
      }
    };
    fetchContacts();
  }, []);

  const startCalls = async () => {
    if (!selectedContacts.length || !agentPhone) {
      toast({
        title: "שגיאה",
        description: "אנא מלא את המספר שלך ובחר אנשי קשר",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setCallStatus('מתחיל שיחות');
    setIsCallActive(true);

    try {
      for (const contactId of selectedContacts) {
        const contact = contacts.find(c => c.id === contactId);
        if (contact) {
          const fromNumber = getNextFromNumber();

          const response = await fetch(`${serverUrl}/api/make_call`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              to_number: formatPhoneNumber(contact.phone),
              from_number: fromNumber,
              agent_number: formatPhoneNumber(agentPhone),
              max_attempts: parseInt(maxAttempts, 10)
            })
          });

          if (response.ok) {
            const data = await response.json();
            /**
             * שרת ה-API מחזיר call_sid ולא call_id,
             * לכן נשמור data.call_sid בתור call_id.
             */
            setCallsMade(prev => ({
              ...prev,
              [contact.id]: {
                number: contact.phone,
                status: 'initiated',
                call_id: data.call_sid // <-- שינוי חשוב: משתמשים ב-call_sid שהשרת מחזיר
              }
            }));
          }
        }
      }

      toast({
        title: "שיחות החלו",
        description: "המערכת החלה בביצוע השיחות",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('שגיאה בהתחלת השיחות:', error);
      toast({
        title: "שגיאה",
        description: "אירעה שגיאה בהתחלת השיחות",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsCallActive(false);
      setCallStatus('שגיאה');
    }
  };

  const checkCallStatus = useCallback(async () => {
    const statusChecks = Object.entries(callsMade).map(async ([contactId, callInfo]) => {
      try {
        // אם call_id לא מוגדר, דלג כדי למנוע 404
        if (!callInfo.call_id) return null;

        const response = await fetch(`${serverUrl}/api/call_status/${callInfo.call_id}`);
        if (response.ok) {
          const data = await response.json();
          return { contactId, status: data.status };
        }
      } catch (error) {
        console.error(`שגיאה בבדיקת סטטוס לשיחה ${callInfo.call_id}:`, error);
      }
      return null;
    });

    const results = await Promise.all(statusChecks);
    const updatedCalls = { ...callsMade };
    
    results.forEach(result => {
      if (result && result.contactId) {
        updatedCalls[result.contactId] = {
          ...updatedCalls[result.contactId],
          status: result.status
        };
      }
    });

    setCallsMade(updatedCalls);
  }, [callsMade, serverUrl]);

  useEffect(() => {
    let interval;
    if (isCallActive) {
      interval = setInterval(checkCallStatus, 3000);
    }
    return () => clearInterval(interval);
  }, [isCallActive, checkCallStatus]);

  const stopCalls = async () => {
    try {
      const stopPromises = Object.values(callsMade).map(async (callInfo) => {
        if (callInfo.call_id) {
          return fetch(`${serverUrl}/api/end_call/${callInfo.call_id}`, {
            method: 'POST'
          });
        }
      });

      await Promise.all(stopPromises);
      
      setIsCallActive(false);
      setCallStatus('הופסק');
      toast({
        title: "השיחות הופסקו",
        description: "כל השיחות הפעילות הופסקו",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('שגיאה בהפסקת השיחות:', error);
      toast({
        title: "שגיאה",
        description: "נכשל בהפסקת השיחות",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'initiated':
        return <Badge colorScheme="blue">מתחיל</Badge>;
      case 'in-progress':
        return <Badge colorScheme="green">פעיל</Badge>;
      case 'completed':
        return <Badge colorScheme="gray">הסתיים</Badge>;
      case 'failed':
        return <Badge colorScheme="red">נכשל</Badge>;
      case 'connected':
        return <Badge colorScheme="purple">מחובר</Badge>;
      case 'timeout':
        return <Badge colorScheme="yellow">לא ענה</Badge>;
      default:
        return <Badge colorScheme="yellow">לא ידוע</Badge>;
    }
  };

  return (
    <Box mt={4}>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="המספר שלך (לחיבור השיחות)"
          value={agentPhone}
          onChange={(e) => setAgentPhone(e.target.value)}
        />
        <Input
          placeholder="מספר ניסיונות מקסימלי"
          value={maxAttempts}
          onChange={(e) => setMaxAttempts(e.target.value)}
          type="number"
        />
        <HStack>
          <Button
            leftIcon={<PhoneIcon />}
            colorScheme="teal"
            onClick={startCalls}
            isDisabled={isCallActive}
          >
            התחל שיחות
          </Button>
          <Button
            leftIcon={<SmallCloseIcon />}
            colorScheme="red"
            onClick={stopCalls}
            isDisabled={!isCallActive}
          >
            הפסק שיחות
          </Button>
        </HStack>
        
        <Text fontWeight="bold">סטטוס: {callStatus}</Text>
        
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>שם</Th>
              <Th>מספר טלפון</Th>
              <Th>סטטוס</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(callsMade).map(([contactId, callInfo]) => {
              const contact = contacts.find(c => c.id === contactId);
              if (!contact) return null;
              
              return (
                <Tr key={contactId}>
                  <Td>{contact.name}</Td>
                  <Td>{displayPhoneNumber(contact.phone)}</Td>
                  <Td>{getStatusBadge(callInfo.status)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  );
};

export default CallSection;
